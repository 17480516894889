import * as React from 'react'
import { Location } from '@reach/router'
import { css } from 'linaria'
import { Link } from 'gatsby'

const styleFooter = css`
  margin-bottom: 4rem;
  font-size: 1rem;

  ul {
    padding-left: 0;
  }
`

const styleDash = css`
  color: gray;
`

const PAGE_URL = '/subscribe'

export const Footer: React.FC = () => {
  return (
    <footer className={styleFooter}>
      <span className={styleDash}>—</span>
      <ul>
        <li>
          twitter &middot; {' '}
          <a href="https://twitter.com/DerekNguyen10">@DerekNguyen10</a>
        </li>
        <li>
          github &middot; {' '}
          <a href="https://github.com/d4rekanguok">d4rekanguok</a>
        </li>
      </ul>
      <Location>
        {({ location }) => {
          return location.pathname === PAGE_URL
            ? null
            : <p><Link to={PAGE_URL}>subscribe</Link> to this blog?</p>
        }}
      </Location>
      <p><small>Have a good day!</small></p>
    </footer>
  )
}