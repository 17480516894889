import * as React from 'react'
import Helmet, { HelmetProps } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

interface Props {
  title: string;
  description?: string;
  thumbnail?: string;
  meta?: HelmetProps['meta'];
}

export const SEO: React.FC<Props> = ({
  description = '',
  thumbnail = '',
  meta = [],
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  if (thumbnail) {
    meta.push({
      property: 'og:image',
      content: thumbnail,
    }, {
      property: 'twitter:image',
      content: thumbnail,
    }, {
      property: 'og:image:width',
      content: '1200',
    }, {
      property: 'og:image:height',
      content: '630',
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[{
        rel: 'icon',
        type: 'image/png',
        href: '/favicon-64.png',
      }, {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon-128.png',
      }, {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon-256.png',
      }]}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        ...meta
      ]}
    />
  )
}
