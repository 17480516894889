import * as React from 'react'
import { Link } from 'gatsby'
import { css } from 'linaria'

const styleName = css`
  font-weight: bold;
  a {
    color: var(--color-foreground);
    text-decoration: none;
  }
`

const styleHeader = css`
  margin: 1rem 0 3rem;
`

export const Header: React.FC = () => {
  return (
    <header className={styleHeader}>
      <div className={styleName}><Link to='/'>byderek.com</Link></div>
    </header>
  )
}