import * as React from 'react'
import { css } from 'linaria'
import 'normalize.css'
import './theme.css'

import { Header } from './header'
import { Footer } from './footer'

const styleContainer = css`
  max-width: 43rem;
  margin: 0 auto;
  padding: 0 1.5rem;

  font-size: 1.2rem;
  line-height: 1.5;

  ul {
    list-style-type: none;
  }

  main {
    margin-bottom: 5rem;
  }
`

export const Layout: React.FC = ({ children }) => {
  return (
    <div className={styleContainer}>
      <Header />
      <main>
        { children }
      </main>
      <Footer />
    </div>
  )
}